<template>
<div class="bg--info">
  <header-block cls=" header__menu--info"/>
  <div class="container container--info">
    <div class="info">
      <h1 class="title__info">
        3 секрета гармоничных отношений.
      </h1>
      <p class="info__txt">
        Долгие, счастливые, гармоничные отношения – об этом мечтает
        каждая пара в начале своего пути! Но интернет постоянно
        выдает заголовки о расставании в звездных парах,
        и многие начинают отчаиваться. Не стоит грустить!
        Везде есть свои секреты, и долгие, наполненные любовью,
        гармоничные отношения – не исключение.
      </p>
      <div class="info__block">
        <p class="info__txt">
          1. Доверие.
        </p>
        <p class="info__txt">
          Это база. Основа. Без доверия ничего не получится.
          Когда ты доверяешь своему партнеру, то в душе нет
          места тревогам, неврозу, злости на себя и на него.
          В отношениях доверие проявляется:
        </p>
        <br>
        <ul>
          <li>
            <p class="info__txt info__txt--small">
              в любой момент времени находясь на расстоянии,
              вы можете сообщить партнеру где и с кем вы находитесь
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
            ваш телефон находится в зоне доступа вашего партнера,
            он/она может им воспользоваться, посмотреть контакты, фотографии, переписки
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              вы можете честно сообщать куда вы планируете поехать
              в отпуск, сколько денег вы собираетесь потратить
            </p>
          </li>
        </ul>
      </div>
      <p class="info__txt">
        Если же вы понимаете, что любите человека, но доверием в вашей паре не пахнет –
        повод задуматься и посмотреть на вашу собственную родительскую модель.
        Как было в вашей семье, когда вы были ребенком?
        Как вели себя папа и мама по отношению друг к другу?
        Делились всем, или фраза « Не говори папе» часто была в мамином лексиконе?
      </p>
      <div class="info__block">
        <p class="info__txt">
          2. Принятие.
        </p>
        <p class="info__txt">
          «Я тебя принимаю» - означает
          «Я вижу тебя целиком, со всеми твоими достоинствами, недостатками,
          привычками – и я позволяю этому быть в наших отношениях».
        </p>
        <br>
        <ul>
          <li>
            <p class="info__txt info__txt--small">
              перестаньте себя критиковать, винить, стыдить в чем-то
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              вы принимаете его вредные привычки, не акцентируя на них внимание,
              убираете упреки и надежду это убрать или изменить
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              вы принимаете его характер, выделяю позитивные черты,
              и не придаете большого значения негативным
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              вы разделяете его ценности, относитесь к ним без осуждения
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              вы разделяете его мнение по поводу важных аспектов в вашей совместной жизни
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              вы принимаете его родственников, друзей, бывших партнеров с уважением, понимая,
              что благодаря (или вопреки) этот человек сейчас такой.
            </p>
          </li>
        </ul>
        <br>
        <p class="info__txt">
          Если вы понимаете, что какой-то аспект для вас категорически
          не приемлем, например он охотник, а вы член Общества
          защиты дикой природы, то в таком случае стоит задуматься,
          а туда ли вы вообще идете с этим человеком? При наличии
          конфликтных интересов, привычек, гармоничных отношений не построить.
        </p>
      </div>
      <br>
      <br>
      <div class="info__block">
        <p class="info__txt">
          3. Совместные цели.
        </p>
        <p class="info__txt">
          «Если хочешь идти быстро – иди один. Если хочешь идти далеко – иди вместе».
          Общие цели задают направление паре. Появляется чувство единства, сплоченности,
          плеча рядом. Вы команда, и это дает огромную поддержку и чувство уверенности в себе.
          <br>
          Для начала можно широкими мазками нарисовать картину вашего совместного будущего,
          определиться с глобальными целями:
        </p>
        <br>
        <ul>
          <li>
            <p class="info__txt info__txt--small">
              дети. Хотите вы их или нет? Сколько, когда? Свои, приемные?
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              место жительства. В какой стране вы хотите жить, какой образ жизни вести?
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              уровень дохода. Кто сколько зарабатывает, какие роли кто будет исполнять?
            </p>
          </li>
        </ul>
        <br>
        <p class="info__txt">
          И прочее. Все, что важно для каждого члена пары, нужно проговаривать честно.
          Цели могут быть как глобальные, так и ежегодные. Например, зимой у его мамы юбилей,
          и вы решаете вместе туда поехать, а летом вы едите в отпуск,
          опять же важно заранее понимать – отпуск совместный или раздельный.
        </p>
        <p class="info__txt">
          Потратив немного времени на выстраивание гармоничных отношений вы
          сможете наслаждаться ими всю жизнь! Долгие, крепкие, наполненные гармонией,
          счастьем и любовью отношения – абсолютная реальность нашего времени!
        </p>
      </div>
      <router-link class="button" to="/">
        В начало
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'secret-couple',
};
</script>
